import { useEffect, useRef, useState } from "react";
import { getAPICall } from "../../Components/APICall";
import UserPhotoIcon from "../../Components/UserPhotoIcon";
import BeatLoader from "react-spinners/BeatLoader";
import Translate from "../../AppContext/Translate";
import WriteHelper from "../../Components/WriteHelper";
import StudentIcon from "./StudentIcon";
import TeacherIcon from "./TeacherIcon";
import { Link } from "react-router-dom";

function FollowedUser({name, user, userId, token}) {
    const divRef = useRef(null);
    const descRef = useRef(null);
    const hasExecuted = useRef(false);
    const [isStudent, setIsStudent] = useState(false);
    const [isTeacher, setIsTeacher] = useState(false);
    const [overflow, setOverflow] = useState(false);
    const [desc, setDesc] = useState(null);
    
    useEffect(() => {
        const currentDiv = divRef.current;
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting && !hasExecuted.current) {
              onVisible();
              hasExecuted.current = true;
              observer.disconnect();
            }
        }, { threshold: 0.1 });
        if (currentDiv) {
            observer.observe(currentDiv);
        }
        return () => {
            if (currentDiv) {
                observer.unobserve(currentDiv);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (descRef.current) {
            setOverflow(descRef.current.scrollHeight > descRef.current.clientHeight);
        }
    }, [desc]);

    const onVisible = () => {
        getAPICall('user/isStudent', {userId: user}, (data) => {
            if (data && 'student' in data) setIsStudent(data.student === 1);
        });
        getAPICall('user/isTeacher', {userId: user}, (data) => {
            if (data && 'teacher' in data) setIsTeacher(data.teacher === 1);
        });
        getAPICall('user/description', {user: user}, (data) => {
            if (data && data.length > 0) {
                var desc = WriteHelper.parseElements(data[0].description, null, null, null, "tinyTextPost");
                setDesc(desc);
            } else setDesc('');
        });
    };

    return <div className="followedUserFrame">
        <div className="followedUserDiv" ref={divRef}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <UserPhotoIcon user={user} userName={name} big={true}/>
                <div className="top8">
                    {isStudent && <StudentIcon/>}
                    {isStudent && isTeacher && <div style={{height: "6px"}}/>}
                    {isTeacher && <TeacherIcon/>}
                </div>
            </div>
            <p className="fkenyan large top8">{name}</p>
            <div className="top8" style={{height: "2px", width: "70%", backgroundColor: "black"}}/>
            <div className="top8">
                {desc === null ? <BeatLoader/> :
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "250px"}}>
                        <div>
                            <div ref={descRef} style={{height: "190px", overflow: "hidden"}}>
                                {desc}
                            </div>
                            {overflow && <p>...</p>}
                        </div>
                        <div className="center">
                            <Link to={"/load/profile/" + user}>
                                <button style={{backgroundColor: "#12c191", color: "white", border: "none", padding: "3px 10px"}}>
                                    <img alt="" src="/icons/search.png" width="22px" className="right6"/>
                                    <Translate>переглянути</Translate>
                                </button>
                            </Link>
                        </div>
                    </div>}
            </div>
        </div>
    </div>
}

export default FollowedUser