import { useState, useEffect, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Translate, { translate } from '../../AppContext/Translate';
import { PiFileVideoBold } from "react-icons/pi";
import CourseIndex from './CourseIndex';
import { AppContext } from '../../AppContext/AppContext';
import { MediaPath } from '../../Components/MediaPath';
import WriteHelper from '../../Components/WriteHelper';
import { getAPICall, postAPICall } from '../../Components/APICall';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Material from './Material';
import Button from "react-bootstrap/Button";

function CabEditCourseMaterials({course, units, numDays}) {
    var { userId, token, dicLang } = useContext(AppContext);
    const [selUnit, setSelUnit] = useState(null);
    const [projectExists, setProjectExists] = useState(true);
    const [progress, setProgress] = useState(null);
    const [materials, setMaterials] = useState([]);
    const [openState, setOpenState] = useState({});

    const handleToggle = (key) => {
        setOpenState((prevState) => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const getPath = (course, topic, post) => {
        var p = new MediaPath(MediaPath.kind_privateCoursePost);
        p.add(course);
        p.add(selUnit.id);
        p.add(post);
        return p;
    }

    const initMaterials = async (data) => {
        setProgress(0);
        var mat = [];
        for (var i = 0; i < data.length; i++) {
            setProgress(100 * (i + 1) / data.length);
            const m = data[i];
            const msg = await WriteHelper.parseMessage(m.message, getPath(course, m.topic, m.post), userId, token);
            mat.push({post: m.post, course: m.course, lang: m.lang, topic: m.topic, postOrder: mat.length + 1,
                day: m.day, message: msg, taskName: m.taskName, extensions: m.extensions !== null ? m.extensions.split('|') : [],
                project: m.project});
        }
        setMaterials(mat);
        setProgress(null);
    }

    var unsavedMaterials = false;
    for (var i = 0; i < materials.length; i++) {
        if (materials[i].post <= 0) {
            unsavedMaterials = true;
            break;
        }
    }

    const saveError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const updateMaterial = (number, field, value) => {
        const nextMaterials = materials.map((m, i) => {
            if (i + 1 === number) {
                var copy = {...m};
                copy[field] = value;
                return copy;
            } else {
              return m;
            }
        });
        setMaterials(nextMaterials);
    }

    const updateNumbers = (n1, n2) => {
        if (n2 === -1) { // Delete material n1
            const m1 = materials[n1 - 1];
            var copy = [...materials];
            copy.splice(n1 - 1, 1);
            for (var i = 0; i < copy.length; i++) {
                if (copy[i].postOrder > m1.postOrder) copy[i].postOrder--;
            }
            setMaterials(copy);
        } else {
            const m1 = materials[n1 - 1];
            const m2 = materials[n2 - 1];
            postAPICall('materials/order', {userId: userId, token: token, course: m1.course, post1: m1.post,
                post2: m2.post}, (data) => {
                    if (data && 'error' in data && data.error === 'none') {
                        const newMat = materials.map((m, i) => {
                            if (i + 1 === n1) {
                                return {...m2, postOrder: i + 1};
                            } else if (i + 1 === n2) {
                                return {...m1, postOrder: i + 1};
                            }
                            else return m;
                        });
                        setMaterials(newMat);            
                    } else saveError();
                }, saveError);
        }
    }

    const newPost = (kind) => {
        var post = {post: -1, course: course, topic: selUnit.id, postOrder: materials.length + 1,
            day: 0, message: {text: '', files: []}, taskName: null, extensions: null, project: 0}; 
        if (kind === 'task' || kind === 'project') {
            post.taskName = '';
            post.extensions = [];
        }
        if (kind === 'project') {
            post.project = 1;
            setProjectExists(true);
        }
        const newMat = [...materials, post];
        setMaterials(newMat);
    }

    useEffect(() => {
        if (selUnit && units) {
            if (selUnit.number === units.length) {
                getAPICall('materials/project', {userId: userId, token: encodeURIComponent(token),
                    course: course}, (data) => {
                    setProjectExists(data && data.length > 0)
                }, () => { setProjectExists(true); });
            } else setProjectExists(true);
            getAPICall('materials/list', {userId: userId, token: encodeURIComponent(token), course: course,
                topic: selUnit.id}, (data) => {
                    if (data) initMaterials(data);
                    else initMaterials([]);
                }, () => { initMaterials([]); });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selUnit, course, units]);

    return <Accordion.Item eventKey="6">
        <Accordion.Header onClick={() => handleToggle("6")}>
            <PiFileVideoBold size="24" style={{margin: "1px 8px 0px 0px"}}/>
            <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Матеріали</Translate></span>
        </Accordion.Header>
        <Accordion.Body>
            {"6" in openState && openState["6"] && <>
                <CourseIndex units={units} selUnit={selUnit} setSelUnit={setSelUnit}/>
                {selUnit && <h3 style={{marginTop: "8px"}}><Translate>Матеріали обраного розділу</Translate>:</h3>}
                {progress !== null ? <ProgressBar animated now={progress} style={{marginTop: "16px"}} /> : <>
                    {numDays >= 0 && materials.map((mat, i) => {
                        return <Material key={i} material={mat} numDays={numDays} userId={userId} token={token} dicLang={dicLang}
                            numPosts={materials.length} unsavedMaterials={unsavedMaterials} updateMaterial={updateMaterial}
                            updateNumbers={updateNumbers}/>
                    })}
                    {numDays >= 0 && !unsavedMaterials && selUnit && <div style={{textAlign: "center"}}>
                        <Button size="sm" style={{margin: "4px"}} variant="outline-primary" onClick={() => { newPost("post"); }}>
                            <Translate>Новий пост</Translate>
                        </Button>
                        <Button size="sm" style={{margin: "4px"}} variant="outline-success" onClick={() => { newPost("task")}}>
                            <Translate>Нове домашнє завдання</Translate>
                        </Button>
                        {!projectExists && <Button size="sm" style={{margin: "4px"}} variant="outline-info" onClick={() => { newPost("project")}}>
                            <Translate>Фінальна робота</Translate>
                        </Button>}
                    </div>}
                </>}
            </>}
        </Accordion.Body>
    </Accordion.Item>
}

export default CabEditCourseMaterials