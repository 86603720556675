import { useState, useEffect, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Translate, { translate } from '../../AppContext/Translate';
import { FaListOl } from "react-icons/fa6";
import Form from 'react-bootstrap/Form';
import NumericInput from '../../Components/NumericInput';
import { getAPICall, postAPICall } from '../../Components/APICall';
import UnitNumber from '../../Components/UnitNumber';
import { AppContext } from '../../AppContext/AppContext';
import { BiUpArrow, BiDownArrow, BiTrash } from 'react-icons/bi';
import Button from "react-bootstrap/Button";
import WriteHelper from '../../Components/WriteHelper';

function CabEditCourseIndex({course, set, units, setUnits}) {
    var { userId, token, dicLang } = useContext(AppContext);
    const [changes, setChanges] = useState(false);
    const [toDelete, setToDelete] = useState([]);
    const [savedOk, setSavedOk] = useState(null);
    const [openState, setOpenState] = useState({});

    const handleToggle = (key) => {
        setOpenState((prevState) => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        getAPICall('units/course', {course: course.id}, (data) => {
            if (data) setUnits(data);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course]);

    const changeField = (text, unitId, field)  => {
        var copy = units.map((unit, j) => {
            if (unitId === j) {
                var unitCopy = {...unit};
                unitCopy[field] = text;
                return unitCopy;
            } else return unit;
        });
        setUnits(copy);
        setChanges(true);
    }

    const moveUpUnit = (i) => {
        var copy = units.map((unit, k) => {
            if (k === i - 1) return units[i];
            else if (k === i) return units[i - 1];
            else return unit;
        });
        setUnits(copy);
        setChanges(true);
    }

    const nonEditable = () => {
        alert(translate("Цей розділ не може бути видалений, оскільки він містить матеріали", dicLang) + ".");
    }

    const doDeleteUnit = (i) => {
        var newToDelete = [...toDelete];
        if (newToDelete.indexOf(units[i].id) === -1) newToDelete.push(units[i].id);
        var copy = [...units];
        copy.splice(i, 1);
        setUnits(copy);
        setToDelete(newToDelete);
        setChanges(true);
    }

    const deleteUnit = (i) => {
        const unit = units[i];
        const canBeDeleted = 'canBeDeleted' in unit ? unit.canBeDeleted : null;
        if (canBeDeleted === null) {
            getAPICall('materials/list', {userId: userId, token: encodeURIComponent(token),
                course: course.id, topic: unit.id}, (data) => {
                if (!data || (data && data.length > 0)) {
                    changeField(false, i, 'canBeDeleted');
                    nonEditable();
                } else doDeleteUnit(i);
            }, nonEditable);
        } else if (canBeDeleted === true) doDeleteUnit(i);
        else nonEditable();
    }

    const addUnit = () => {
        var copy = [...units];
        copy.push({id: -1, course: course, number: units.length + 1, title: '', description: '', canBeDeleted: true});
        setUnits(copy);
    }

    const doSave = (idUnit) => {
        if (idUnit < units.length) {
            var unit = units[idUnit];
            postAPICall('units/save', {userId: userId, token: token, course: course.id,
                    unitId: unit.id, title: WriteHelper.filterSimpleText(unit.title),
                    description: WriteHelper.filterSimpleText(unit.description), number: idUnit + 1}, (data) => {
                        if (data && 'id' in data) {
                            unit.id = data.id;
                            doSave(idUnit + 1);
                        } else saveError();
                    }, saveError);
        } else {
            setSavedOk(true);
            setChanges(false);
            set(null, null, null, true, false);
        }
    }

    const doDelete = (i) => {
        if (i < toDelete.length) {
            postAPICall('units/delete', {userId: userId, token: token, course: course.id, unitId: toDelete[i]},
                (data) => {
                    if (data && 'error' in data && data.error === 'none') {
                        doDelete(i + 1);
                    } else saveError();
                }, saveError);
        } else {
            setToDelete([]);
            doSave(0);
        }
    }

    const saveError = (msg=null) => {
        const txt = msg === null ? "не вдалося зберегти зміни" : msg;
        alert(translate("Помилка", dicLang) + ": " + translate(txt, dicLang));
    }

    const save = () => {
        setChanges(false);
        setSavedOk(null);
        postAPICall('course/days', {userId: userId, token: token, course: course.id, numDays: course.numDays},
            (data) => {
                if (data && 'error' in data && data.error === 'none') {
                    doDelete(0);
                } else saveError("не можна змінити кількість днів, є матеріали за межами діапазону");
            }, () => { saveError("не можна змінити кількість днів, є матеріали за межами діапазону"); });
    }

    return <Accordion.Item eventKey="5">
        <Accordion.Header onClick={() => handleToggle("5")}>
            <FaListOl size="24" style={{margin: "1px 8px 0px 0px"}}/>
            <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Зміст</Translate></span>
        </Accordion.Header>
        <Accordion.Body>
            {"5" in openState && openState["5"] && <>
                <div style={{display: "flex", alignItems: "baseline", flexWrap: "wrap"}}>
                    <Form.Label style={{marginTop: "8px", marginRight: "8px"}}><Translate>Кількість днів</Translate></Form.Label>
                    <NumericInput value={course.numDays} step={1} precision={0} onChange={(e) => { 
                        set(course.id, 'numDays', e); setChanges(true); }} maxWidth={96}/>
                </div>
                <Form.Label style={{fontWeight: "600"}}><Translate>Теми курсу</Translate>:</Form.Label>
                {units !== null && <>
                    {units.map((unit, i) => {
                        return <div key={i}>
                            <div style={{display: "flex", width: "100%"}}>
                                <UnitNumber number={i + 1}/>
                                <Form.Control value={unit.title} type="text" id={"inputTitle" + i} placeholder={translate("Назва", dicLang)} 
                                    onChange={(e) => { changeField(e.target.value, i, 'title'); }}/>
                            </div>
                            <Form.Control value={unit.description} as="textarea" rows={3} id={"inputDesc" + i}
                                placeholder={translate("Опис", dicLang)} 
                                onChange={(e) => { changeField(e.target.value, i, 'description'); }}/>
                            <div style={{marginTop: "-8px", marginBottom: "10px"}}>
                                <Button variant="link" disabled={i === 0} onClick={() => { moveUpUnit(i); }}><BiUpArrow size={18}/></Button>
                                <Button variant="link" disabled={i === units.length - 1} onClick={() => { moveUpUnit(i+1); }}><BiDownArrow size={18}/></Button>
                                <Button variant="link" onClick={() => { deleteUnit(i); }}><BiTrash size={18}/></Button>
                            </div>
                        </div>
                    })}
                    <div style={{textAlign: "center"}}>
                        <Button variant="outline-primary" onClick={addUnit}>
                            <Translate>Додати</Translate>
                        </Button>
                        <Button style={{marginLeft: "8px"}} disabled={!changes} onClick={save}>
                            <Translate>Зберегти зміни</Translate>
                        </Button>
                    </div>
                    {savedOk && <div style={{backgroundColor: "powderblue", marginTop: "8px"}}><Translate>Зміни успішно збережено</Translate></div>}
                </>}
            </>}
        </Accordion.Body>
    </Accordion.Item>
}

export default CabEditCourseIndex