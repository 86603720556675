import { useState, useRef } from "react";
import { RiCloseLine } from 'react-icons/ri';
import SearchResults from "./SearchResults";
  
function SearchBar({ placeholder, data, size }) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const [searchFullBar, setSearchFullBar] = useState(0);
    const inputRef = useRef();

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        const searchWordL = searchWord.toLowerCase();
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
          return value.title.toLowerCase().includes(searchWordL);
        });
        if (searchWord === "") {
          setFilteredData([]);
        } else {
          setFilteredData(newFilter);
        }
    };

    const iconSearchClick = (event) => {
        setSearchFullBar(1);
    };

    const iconCloseSearchClick = (event) => {
        setSearchFullBar(0);
        clearInput();
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };
    
    if (size !== "big") {
        if (searchFullBar === 0) {
            return <div className='menuDivIcon'>
              <img src="/bkg/lensw.png" className="align-top pointer" height="26" alt=""
                onClick={iconSearchClick} />
            </div>
        } else {
            return (<div className="fullSearchBar bg-dark">
              <div className="search">
                <input autoFocus 
                    type="text"
                    placeholder={placeholder}
                    value={wordEntered}
                    onChange={handleFilter}
                    style={{marginLeft: '12px', width: 'calc(100vw - 54px)', marginTop: "22px"}}
                    ref={inputRef} />
                <RiCloseLine onClick={iconCloseSearchClick} className="closeButtonIcon" color="white" />
                <SearchResults filteredData={filteredData} wordEntered={wordEntered} size={size}/>
              </div>  
            </div>
          );
        }
      } else {
        return (
          <div className="search">
            <div className="searchInputs">
              <input
                type="text"
                placeholder={placeholder}
                value={wordEntered}
                onChange={handleFilter}
                ref={inputRef}
              />
              <div className="searchIcon">
                {filteredData.length === 0 ?
                  <img alt="" src="/bkg/lens.png" height="22" onClick={() => {inputRef.current.focus()}} />
                  : <RiCloseLine id="clearBtn" onClick={clearInput} />
                }
              </div>
            </div>
            <SearchResults filteredData={filteredData} wordEntered={wordEntered} size={size}/>
          </div>
        );
    }
}

export default SearchBar